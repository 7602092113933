<template>
  <div class="tw-overflow-hidden tw-h-full">
    <div class="tw-flex tw-gap-6 tw-h-full">
      <div class="tw-w-3/12">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <h6 class="kb-title">
            <feather-icon class="mr-50" icon="InfoIcon" size="20" />
            <span class="tw-line-clamp-1">Related Articles</span>
          </h6>
          <div v-if="loadingRelatedArticles" class="tw-w-full tw-text-center">
            <os-loader />
          </div>
          <ul class="tw-mt-4 tw-mb-0">
            <li>
              <a
                v-for="article in groupArticles"
                :key="article.id"
                :href="$router.resolve({name: 'knowledge-base-publication', params: {slug: article.id}}).href"
                class="tw-inline-block tw-text-gray-500 tw-px-3 tw-py-1 tw-transition tw-border tw-border-white hover:tw-bg-gray-50 hover:tw-border hover:tw-border-gray-100 tw-w-full tw-rounded-md"
              >
                {{ article.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tw-w-9/12">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl tw-flex tw-flex-col tw-overflow-hidden tw-h-full">
          <div v-if="loadingArticle" class="tw-w-full tw-text-center">
            <os-loader />
          </div>
          <div v-else class="tw-mb-6">
            <div class="tw-mb-2 tw-flex tw-justify-between">
              <p class="tw-mb-0 tw-text-3xl tw-font-semibold tw-text-gray-600">{{ article.title }}</p>
              <div v-if="$can('edit', 'Knowledge-base')" class="tw-flex tw-h-5">
                <svg
                  v-tippy="{content: 'Edit'}"
                  class="tw-ml-1.5 tw-cursor-pointer tw-transition hover:tw-text-sky-700/90"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="$router.push({name: 'knowledge-base-publication-edit', params: {slug: article.id}})"
                >
                  <path
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <svg
                  v-tippy="{content: 'Delete'}"
                  class="tw-ml-1.5 tw-cursor-pointer tw-transition hover:tw-text-red-600/90"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="removeArticle"
                >
                  <path
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p class="tw-text-gray-400">Last updated on {{ handlerDateTime(article.created_at) }}</p>
          </div>

          <TaskTipTapEditor
            v-if="!loadingArticle"
            v-model="article.description"
            class="tw-flex-grow tw-overflow-y-auto board-scroll tw-h-full"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WikiService from '@/services/wiki.service';
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor.vue';
import {handlerDateTime} from '@/helpers/handlers';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    TaskTipTapEditor,
  },
  data() {
    return {
      article: {
        title: '',
        created_at: '',
      },
      kb_question: {
        title: 'Why Was My Developer Application Rejected?',
        lastUpdated: '10 Dec 2018',
        relatedQuestions: [
          {
            id: 0,
            question: 'How Secure Is My Password?',
          },
          {
            id: 1,
            question: 'Can I Change My Username?',
          },
          {
            id: 2,
            question: 'Where Can I Upload My Avatar?',
          },
          {
            id: 3,
            question: 'How Do I Change My Timezone?',
          },
          {
            id: 4,
            question: 'How Do I Change My Password?',
          },
        ],
        // ! Here we have used require for image source but in API it shall be URL of live image, this is just for demo purpose
        content: `<p>It has been said that astronomy is a humbling and character-building experience. There is perhaps no better demonstration of the folly of human conceits than this distant image of our tiny world. To me, it underscores our responsibility to deal more kindly with one another, and to preserve and cherish the pale blue dot, the only home we’ve ever known. The Earth is a very small stage in a vast cosmic arena. Think of the rivers of blood spilled by all those generals and emperors so that, in glory and triumph, they could become the momentary masters of a fraction of a dot. Think of the endless cruelties visited by the inhabitants of one corner of this pixel on the scarcely distinguishable inhabitants of some other corner, how frequent their misunderstandings, how eager they are to kill one another, how fervent their hatreds.</p><p class="ql-align-center"><img class="img-fluid w-100" src="${require('@/assets/images/pages/kb-image.jpg')}"></p></p><h5>Houston</h5><p>that may have seemed like a very long final phase. The auto targeting was taking us right into a … crater, with a large number of big boulders and rocks … and it required … flying manually over the rock field to find a reasonably good area.</p><ul><li><a href="javascript:void(0)" rel="noopener noreferrer" >I am a stranger. I come in peace. Take me to your leader and there will be a massive reward for you in eternity.</a></li><li><a href="javascript:void(0)" rel="noopener noreferrer" >It’s just mind-blowingly awesome. I apologize, and I wish I was more articulate, but it’s hard to be articulate when your mind’s blown—but in a very good way.</a></li><li><a href="javascript:void(0)" rel="noopener noreferrer" >A good rule for rocket experimenters to follow is this</a></li></ul>`,
      },
      loadingRelatedArticles: false,
      loadingArticle: false,
      value: null,
      groupArticles: [],
    };
  },
  methods: {
    handlerDateTime,
    getArticle() {
      this.loadingArticle = true;
      this.loadingRelatedArticles = true;
      WikiService.getArticle(this.$route.params.slug).then(r => {
        this.article = r.data;
        this.loadingArticle = false;
        this.getGroup();
      });
    },
    getGroup() {
      WikiService.getGroup(this.article.group.id).then(r => {
        this.groupArticles = r.data['publications'];
        this.loadingRelatedArticles = false;
      });
    },
    removeArticle() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          size: 'sm',
        })
        .then(value => {
          if (value) {
            WikiService.deleteArticle(this.$route.params.slug).then(() => {
              this.$router.push({name: 'knowledge-base'});
            });
          }
        });
    },
  },
  mounted() {
    this.getArticle();
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.router-link-exact-active {
  color: $primary !important;
}

.list-group-item {
  padding: 0.45rem 1.25rem;

  &::after {
    top: 0.85rem !important;
  }
}
</style>